<template>
    <div class="container-stats">
        <div class="container-statistics mt-5">
            <h1 class="mt-5">Welcome Bharath</h1>
            <div v-for="(stats, index) in allStatsData" :key="index">
                <div class="d-flex mt-5 align-items-center">
                    <div :class="{ active: isActive === stats.selected }" class="allstats-country">{{
                            stats.country
                    }}</div>
                    <!-- <div class="w-100 my-3 d-flex flex-column">
                    <h3 class="ProximaNovaBold">{{ stats.name }}</h3>
                </div> -->
                    <div class="w-100 d-flex">
                        <w-chart-overview :chartSummary="stats.value"></w-chart-overview>
                        <div class="icon d-none">
                            <img src="@/assets/eye-line.png" alt="hide" v-if="activeModel === index"
                                @click="showChartData(stats, index)" />
                            <img src="@/assets/eye-close-line bold.svg" @click="showChartData(stats, index)" alt="view"
                                v-else />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <w-button style="width: 256px; margin-right: 50px" :buttonLoader="'normal'" :buttonText="'Continue'"
            @buttonClicked="handleContinue" class="mt-5" />

        <div class="container-line-chart d-none" v-if="activeModel !== 'none'">
            <div class="title">Actual vs Prediction of {{ modelName }}</div>

            <ColumnChart :chartData="columnChartData" />
        </div>
    </div>
</template>
<script>
import ColumnChart from "@/components/Chart/ColumnChart.vue";
import ChartOverview from "@/components/Summary/ChartOverview.vue";
// import { AimoSolutionsServices } from "@/services/AimoSolutionsServices.js";
// import { AimoUnileverServices } from "@/services/AimoUnileverServices.js";
import Button from "@/components/Profile/Button.vue";
// import modelsSummary from "@/response/modelsSummary.json"
// const aimoSolutionsServices = new AimoSolutionsServices();
// const aimoUnileverServices = new AimoUnileverServices();

import { AimoDatapoemServices } from "@/services/AimoDatapoemServices.js";
const aimoDatapoemService = new AimoDatapoemServices();

export default {
    name: "Stats",
    components: {
        ColumnChart,
        "w-button": Button,
        "w-chart-overview": ChartOverview,
    },
    data() {
        return {
            activeClass: "",
            dropdownLabel: "",
            clientId: sessionStorage.getItem("subId"),
            count: 0,
            modelName: "",
            marketType: localStorage.getItem("selectedKPISource"),
            selectedBrandKPI: localStorage.getItem("selectedBrandKPI"),
            activeModel: "none",
            selectedstat: "",
            statsDropdown: [
                { text: "Elite", id: "elite" },
                { text: "Pro", id: "pro" },
                { text: "Lite Plan", id: "Lite Plan" },
            ],
            selectedBrand: {
                text: localStorage.getItem("selectedBrandKPI"),
                id: localStorage.getItem("selectedBrandKPI"),
            },
            brandDropdown: [],
            mediaDropdown: [],
            selectedMedia: {
                text: localStorage.getItem("selectedMediaKPI"),
                id: localStorage.getItem("selectedMediaKPI"),
            },
            columnChartData: {
                data: [
                    {
                        name: "",
                        data: [],
                    },
                ],
                xAxis: {
                    categories: ["2018", "2019"],
                    crosshair: true,
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: "Growth",
                    },
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.4,
                        borderWidth: 0,
                    },
                    series: {
                        borderRadiusTopLeft: 25,
                        borderRadiusTopRight: 25,
                    },
                },
                color: ["#18ffff", "#ffd740"],
                title: {
                    text: "Investment vs ROI by Channel(Cr) from 01-SEPT-2021 to 15-NOV-2021",
                    align: "left",
                    margin: 60,
                    style: {
                        color: "#222a37",
                        fontSize: "20px",
                        fontFamily: "ProximaNovaBold",
                    },
                },
                subtitle: {},
                legend: {
                    enabled: false,
                },
                tooltip: {
                    headerFormat: `<span style="font-size:10px" class="mx-2 my-1 p-0">{point.key}</span><table>`,
                    pointFormat:
                        '<tr><td style="color:{series.color};padding:0" class="mx-2 my-1 p-0">{series.name}: </td>' +
                        '<td style="padding:0" class="mx-2 my-1 p-0"><b>{point.y:.1f}</b></td></tr>',
                    footerFormat: "</table>",
                    shared: true,
                    useHTML: true,
                    shadow: false,
                    style: {
                        padding: 0,
                    },
                },
            },
            salseStatics: [
                { name: "R2_Score", value: "0.86", className: "blue-pastel" },
                { name: "Actual_Mean", value: "21.05M", className: "green-pastel" },
                { name: "Predicted_Mean", value: "21.91M", className: "pink-pastel" },
                { name: "Actual_Std", value: "17.62M", className: "red-pastel" },
                { name: "Predicted_Std", value: "21.84M", className: "yellow-pastel" },
            ],
            allStatsData: [
                {
                    name: "",
                    value: [
                        {
                            name: "Budget",
                            value: '36.23K',
                            className: "blue-pastel",
                            image: "Train Mape.svg",
                        },
                        {
                            name: "Marketing ROI",
                            value: 4.4,
                            className: "green-pastel",
                            image: "Test Mape.svg",
                        },
                        {
                            name: "Performance Market ROI",
                            value: 6.2,
                            className: "pink-pastel",
                            image: "Test Error.svg",
                        },
                        {
                            name: "Brand Market ROI",
                            value: 3.3,
                            className: "red-pastel",
                            image: "Holdout Error.svg",
                        },
                        {
                            name: "Media ROI",
                            value: 2.3,
                            className: "red-pastel",
                            image: "Holdout Error.svg",
                        },
                    ],
                    selected: true,
                    country: "Product 1"
                },
                {
                    name: "",
                    value: [
                    {
                            name: "Budget",
                            value: '15.61K',
                            className: "blue-pastel",
                            image: "Train Mape.svg",
                        },
                        {
                            name: "Marketing ROI",
                            value: 2.7,
                            className: "green-pastel",
                            image: "Test Mape.svg",
                        },
                        {
                            name: "Performance Market ROI",
                            value: 3.2,
                            className: "pink-pastel",
                            image: "Test Error.svg",
                        },
                        {
                            name: "Brand Market ROI",
                            value: 1.9,
                            className: "red-pastel",
                            image: "Holdout Error.svg",
                        },
                        {
                            name: "Media ROI",
                            value: 4.7,
                            className: "red-pastel",
                            image: "Holdout Error.svg",
                        },
                    ],
                    country: "Product 2"
                },
                {
                    name: "",
                    value: [
                    {
                            name: "Budget",
                            value: '18.2K',
                            className: "blue-pastel",
                            image: "Train Mape.svg",
                        },
                        {
                            name: "Marketing ROI",
                            value: 2.9,
                            className: "green-pastel",
                            image: "Test Mape.svg",
                        },
                        {
                            name: "Performance Market ROI",
                            value: 4.8,
                            className: "pink-pastel",
                            image: "Test Error.svg",
                        },
                        {
                            name: "Brand Market ROI",
                            value: 3.2,
                            className: "red-pastel",
                            image: "Holdout Error.svg",
                        },
                        {
                            name: "Media ROI",
                            value: 5.4,
                            className: "red-pastel",
                            image: "Holdout Error.svg",
                        },
                    ],
                    country: "Product 3"

                },
                {
                    name: "",
                    value: [
                    {
                            name: "Budget",
                            value: '22.13K',
                            className: "blue-pastel",
                            image: "Train Mape.svg",
                        },
                        {
                            name: "Marketing ROI",
                            value: 3.1,
                            className: "green-pastel",
                            image: "Test Mape.svg",
                        },
                        {
                            name: "Performance Market ROI",
                            value: 4.7,
                            className: "pink-pastel",
                            image: "Test Error.svg",
                        },
                        {
                            name: "Brand Market ROI",
                            value: 2.1,
                            className: "red-pastel",
                            image: "Holdout Error.svg",
                        },
                        {
                            name: "Media ROI",
                            value: 3.5,
                            className: "red-pastel",
                            image: "Holdout Error.svg",
                        },
                    ],
                    country: "Product 4"
                },
            ],
            classess: [
                "blue-pastel",
                "green-pastel",
                "pink-pastel",
                "red-pastel",
                "yellow-pastel",
                "purple-pastel",
                "green-pastel",
            ],
        };
    },
    methods: {
        handleContinue() {
            this.$router.push("/demo/ecomm/data-connection-platform/digital")
        },
        getSelectedOptionCampaign(selectedOption) {
            let text = selectedOption.target.innerText
            if (
                text === "India"
            ) {
                //this.showBlock = "showPreviousHistoryConfirmation";
                this.activeClass = selectedOption;
            }
        },
        saveKPISourceSelected() {
            localStorage.setItem(
                "selectedKPISource",
                `${this.selectedBrand.id}_${this.selectedMedia.id}`
            );
            this.marketType = `${this.selectedBrand.id}_${this.selectedMedia.id}`;
            localStorage.setItem("selectedBrandKPI", this.selectedBrand.text);
            this.getAllModel();
        },
        selectBrand(e) {
            this.selectedBrand = e;
            this.saveKPISourceSelected();
            // this.getAllModel()
        },
        selectMedia(e) {
            this.selectedMedia = e;
            this.saveKPISourceSelected();
            // this.addAllTimeData();
            // this.addsalseStatics();
            // this.saveToLocal();
            // this.callApis();
        },
        // getStaticsData() {
        //   this.salseStatics = [];
        //   this.allStatsData = [];
        //   aimoSolutionsServices
        //     .getChartData(
        //       "actual vs prediction",
        //       "/b2c/in/",
        //       "salesstatistics",
        //       "no"
        //     )
        //     .then((res) => {
        //       for (var j = 1; j < 6; j++) {
        //         var salseStatics = [];
        //         for (var i = 0; i < res.length; i++) {
        //           salseStatics.push({
        //             name: res[i].name,
        //             value: res[i].value,
        //             className: this.classess[i],
        //             image: res[i].name + ".svg",
        //           });
        //         }
        //         this.allStatsData.push({ name: "Model " + j, value: salseStatics });
        //       }

        //       this.count++;
        //     })
        //     .catch(() => {
        //       this.count++;
        //     });
        // },
        // getChartData() {
        //   this.columnChartData.data[0].data = [];
        //   this.columnChartData.xAxis.categories = [];
        //   aimoSolutionsServices
        //     .getChartData(
        //       "actual vs prediction",
        //       "/b2c/in/",
        //       "actualvsprediction",
        //       "no"
        //     )
        //     .then((res) => {
        //       for (var i = 0; i < res.length; i++) {
        //         this.columnChartData.data[0].data.push(res[i].value);
        //         this.columnChartData.xAxis.categories.push(res[i].name);
        //       }
        //       this.count++;
        //     })
        //     .catch(() => {
        //       this.count++;
        //     });
        // },
        getAllModel() {
            this.plans = [];
            console.log(this.selectedBrand.text);
            aimoDatapoemService
                .getChartData(
                    this.selectedBrand.text,
                    this.selectedMedia.id,
                    "modelsummary",
                    this.clientId
                )
                .then((res) => {
                    var res_data = res.response;
                    this.count++;
                    this.allStatsData = [];
                    for (var j = 0; j < res_data.length; j++) {
                        var salseStatics = [];
                        for (var i = 0; i < res_data[j].statics.length; i++) {
                            salseStatics.push({
                                name: res_data[j].statics[i].name,
                                value: res_data[j].statics[i].value,
                                className: this.classess[i],
                                image: res_data[j].statics[i].name + ".svg",
                            });
                        }
                        this.allStatsData.push({
                            name: res_data[j].title,
                            value: salseStatics,
                            chartData: res_data[j].value,
                        });
                        // if (j === 0) {
                        //   this.modelName = res[j].title;
                        //   this.showChartData(this.allStatsData[0], 0);
                        // }
                    }
                })
                .catch(() => {
                    this.count++;
                });
        },
        showChartData(data, index) {
            if (this.activeModel === index) {
                this.activeModel = "none";
                return;
            }
            this.activeModel = index;
            this.modelName = data.title;
            this.columnChartData.data[0].data = [];
            this.columnChartData.xAxis.categories = [];
            for (var i = 0; i < data.chartData.length; i++) {
                this.columnChartData.data[0].data.push(data.chartData[i].value);
                this.columnChartData.xAxis.categories.push(data.chartData[i].name);
            }
        },
        selectOption(e) {
            this.selectedstat = e;
        },
        nextToTheInsights() {
            this.$router.push("/solutions/dp/attribution");
        },
        getBrands(cliendId) {
            this.brandDropdown = [];
            this.mediaDropdown = [];
            aimoDatapoemService
                .getClientBrandDetails(cliendId)
                .then((res) => {
                    let dropdown_labels = Object.keys(res[0]);
                    this.dropdownLabel = dropdown_labels[1];

                    let brand = res[0].Brand;
                    let kpi = res[0][this.dropdownLabel];

                    for (var brands = 0; brands < brand.length; brands++) {
                        this.brandDropdown.push({ id: brand[brands], text: brand[brands] });
                    }

                    for (var kpis = 0; kpis < kpi.length; kpis++) {
                        this.mediaDropdown.push({ id: kpi[kpis], text: kpi[kpis] });
                    }

                    if (this.selectedBrand.text === "undefined") {
                        this.selectedBrand = this.brandDropdown[0];
                    } else if (this.selectedMedia.text === "undefined") {
                        this.selectedMedia = this.mediaDropdown[0];
                    }

                    this.saveKPISourceSelected();

                    // this.selectedBrand = this.brandDropdown[0]
                    // this.selectedMedia = this.mediaDropdown[0]
                })
                .catch((err) => {
                    alert(err);
                });
        },
    },
    created() {
        sessionStorage.setItem("activeForm", 1)
        sessionStorage.setItem("demoType", "ecomm")
        //  this.getStaticsData();
        //  this.getChartData();
        // this.getAllModel();
        // this.getBrands(this.clientId);
    },
};
</script>

<style lang="css" scoped>
.container-stats {

    width: 93%;
    margin: 71px auto 0 auto;
}

.icon {
    align-items: center;
    background: #fff;
    padding-right: 10px;
}

.container-statistics {

    padding-top: 1px;
}

.title {
    font-family: ProximaNovaBold;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #313846;
    margin-top: 48px;
    margin-bottom: 32px;
}

.wrapper-statistics {
    box-shadow: 0px 0px 16px -13px rgb(0 0 0);
    border-radius: 4px;
    min-height: 126px;
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 2rem;
}

.item {
    display: flex;
    width: 20%;
    position: relative;
}

.item:not(:last-child):before {
    content: "";
    position: absolute;
    height: 40px;
    width: 1px;
    background: #e8edf5;
    right: 20px;
    top: 8px;
}

.item-img {
    background: #67e8ff;
    border-radius: 50%;
    padding: 2rem;
    height: 56px;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.item-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 24px;
}

.item-text__title {
    font-family: ProximaNovaRegular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8394b4;
    padding-bottom: 1rem;
}

.item-text__subtitle {
    font-family: ProximaNovaBold;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #313846;
}

.btn-effect {
    width: 190px;
    height: 60px;
    font-family: ProximaNovaRegular;
    background-color: #050505;
    color: #f3f3f3;
    border: none;
    text-align: left;
    font-size: 16px;
    padding-left: 20px;
}

.allstats-country {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    background-color: #949191;
    color: #fff;
    height: 126px;
    margin-right: 15px;
    font-size: 20px;
    font-weight: bold;
    border: 3px solid;
    border-color: white;
    border-radius: 8px;
}

.allstats-country.active {
    background-color: #d6d2d2;

    color: #000;
}

.btn-effect i {
    font-size: 14px;
    float: right;
    margin-top: 7px;
    margin-right: 10px;
}

.active i {
    color: #4BB543;
}

.button.active {
    background-color: #949191;
}

.active .check {
    opacity: 1;
}

.check {
    margin: 1rem;
}

.check i {
    color: rgb(217, 46, 114);
}

.option-box:hover {
    opacity: 0.9;
}

.check {
    text-align: end;
    line-height: 13px;
    margin-bottom: 5px;
    opacity: 0;
}

.check i {
    color: rgb(217, 46, 114);
}

.option-box:hover .check {
    opacity: 1;
}

.active i {
    color: #4BB543;
}

.option-box.active {
    background-color: #ffffff;
    border: 3px solid;
    border-color: rgb(217, 46, 114);
    color: #000;
}

.active .check {
    opacity: 1;
}
</style>
