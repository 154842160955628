import { HotifyApiHelper } from "./helper/HotifyApiHelper";
const hotifyApiHelper = new HotifyApiHelper();

import { ApiHelper } from "./helper/ApiHelper";
const apiHelper = new ApiHelper();

export class AimoDatapoemServices {
    constructor() { }
    getChartData(Brand, KPI, keyword, clientId) {
        const uri = `process/v1/uniliverData?clientId=${clientId}&brand=${Brand}&kpi=${KPI}&keyword=${keyword}`;
        return hotifyApiHelper.get(uri);
    }
    getOptimizationData(data) {
        const uri = `aioptimise/v1/runOptimiserv1`;
        return hotifyApiHelper.post(uri, data);
    }

    downloadOptimizationData(data, filename) {
        const uri = `aioptimise/v1/runOptimiserv1`;
        return hotifyApiHelper.downloadFile(filename, uri, data);
    }
    getOptimizationConstraints(clientId, brand, kpi) {
        const uri = `aioptimise/v1/constraints?runId=${clientId}&brand=${brand}&kpi=${kpi}`
        return hotifyApiHelper.get(uri)
    }
    getScenarioOptimizedData(data) {
        const uri = `aioptimise/v1/scenarioplanningv2`;
        return hotifyApiHelper.post(uri, data)
    }
    submitScenarioForOptimization(data) {
        const uri = `process/v1/schedulescenarios`
        return hotifyApiHelper.post(uri, data)
    }
    downloadForcastingTemplate(brand, kpi, numOfWeeks, startDate) {
        const uri = `aioptimise/v1/forecastingv1?brand=${brand}&kpi=${kpi}&numberofweeks=${numOfWeeks}&startDate=${startDate}`
        return hotifyApiHelper.downloadFileGET(brand, uri)
    }
    uploadForcastingTemplate(data, name, brand, kpi, id, clientId) {
        const uri = `aioptimise/v1/summaryforecastingv1?kpi=${kpi}&brand=${brand}&scenario_id=${id}&scenario_name=${name}&clientId=${clientId}`;
        return hotifyApiHelper.uploadFile(uri, data);
    }
    getSummaryTableData(clientId) {
        const uri = `process/v1/optimisationHistory?clientId=${clientId}`;
        return hotifyApiHelper.get(uri)
    }
    viewResultFromHistory(runId, clientId) {
        const uri = `process/v1/optimisationResultv1?runId=${runId}&clientId=${clientId}`;
        return hotifyApiHelper.get(uri)
    }
    getSummaryDetails(runId, clientId) {
        const uri = `aioptimise/v1/getforecastingv1?clientId=${clientId}&runId=${runId}`;
        return hotifyApiHelper.get(uri)
    }
    getAllSummaries(brand, kpi, clientId) {
        const uri = `aioptimise/v1/summaryforecastingv1?brand=${brand}&kpi=${kpi}&clientId=${clientId}`;
        return hotifyApiHelper.get(uri)
    }
    getPreviousBudget(brand, duration, clientId) {
        const uri = `aioptimise/v1/getLastBudget?brand=${brand}&duration=${duration}&clientId=${clientId}`;
        return hotifyApiHelper.get(uri)
    }
    getForecastingResults(data) {
        const uri = `aioptimise/v1/forecastingscenariov2`
        return hotifyApiHelper.post(uri, data)
    }
    scenarioPlanningV2(data) {
        const uri = `aioptimise/v1/scenarioplanningv2`
        return hotifyApiHelper.post(uri, data)
    }
    getBBOSplit(data) {
        const uri = `aioptimise/v1/BBOsplit`
        return hotifyApiHelper.post(uri, data)
    }
    getBBOScenarioPlanning(brand, duration, runId, clientId) {
        const uri = `aioptimise/v1/scenariotemplate?brand=${brand}&duration=${duration}&runId=${runId}&clientId=${clientId} `
        return hotifyApiHelper.downloadExcelFileGET("Scenario Planning Results", uri)
    }
    optimizeBBOScenarioPlanning(brand, duration, runId, data, clientId) {
        const uri = `aioptimise/v1/scenarioplanningv2?brand=${brand}&duration=${duration}&runId=${runId}&clientId=${clientId}`
        return hotifyApiHelper.post(uri, data)
    }
    getLevelDropdownDetails(brand, clientId) {
        const uri = `aioptimise/v1/constraints?runId=jraygmldoaodfntonhde&brand=${brand}&kpi=Online&clientId=${clientId}`
        return hotifyApiHelper.get(uri)
    }
    getClientBrandDetails(clientId) {
        const uri = `aioptimise/v1/clientDetails?clientId=${clientId}`
        return hotifyApiHelper.get(uri)
    }
    getHospitalityLevelsData() {
        const uri = `https://newui.datapoem.ai/api/aimo/getGraphdata?screenName=levels&instance=/b2c/in/&graphTitle=structured&kpiMarket=allindiahospitality`
       return apiHelper.get(uri)

    }
} 